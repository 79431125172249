<template>

<div class="container">
  <div class="row vertical-offset">
    <div class="col-sm-10 col-sm-offset-1 col-md-4 col-md-offset-4">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">Please choose your email address</h3>
        </div>
        <div class="panel-body">
          <form accept-charset="UTF-8" role="form" class="" @submit.prevent="submit">
            <fieldset>
              <div class="col-sm-6 col-xs-12">
                <div class="input-group">
                  <input class="form-control" v-model="user" placeholder="E-mail" name="email" type="text">
                  <div class="input-group-addon">@</div>
                </div>
              </div>
              <div class="col-sm-6 col-xs-12">
                <select v-model="host" class="form-control">
                  <option v-for="domain in uiDomains"
                    :selected="domain == host ? 'selected' : ''"
                    :value="domain">
                   {{ domain }}
                  </option>
                </select>
              </div>
              <div class="col-xs-12">
                <input class="btn btn-lg btn-success btn-block" type="submit" value="Go to inbox">
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MailboxFormatter from 'components/mailbox_formatter'

export default {

  data: function () {
    return {
      user: '',
      host: this.$store.getters.uiDomains[0],
    }
  },

  computed: {
    ...mapGetters([
      'currentMailbox',
      'uiDomains',
    ])
  },

  methods: {
    submit () {
      const box = MailboxFormatter.prepare(this.user, this.host);
      this.$store.dispatch('switchToMailbox', box);
    }
  },

  watch: {
    currentMailbox (newValue) {
      if (newValue) {
        this.$router.push({
          name: 'inbox',
          params: {
            mailbox: newValue.name,
          },
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
[class^=col-] {
  padding: 0;
}

@media (max-width: 767px) {
  [class^=col-] {
    padding: 15px;
  }
}
.btn {
  margin-top: 20px;
}

.vertical-offset {
  padding-top: 100px;
}

</style>
