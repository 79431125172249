<style lang="scss" scoped>
@import "~assets/_my_variables.scss";

.panel {
  background: $background-color;
  border-radius: 0;
  border: none;
  margin-bottom: 0;

  .panel-heading {
    background: $blue;
    border-color: $grey;
    border-radius: 0;
    color: #fff;

    .close {
      color: #fff;
      opacity: 1;
      text-shadow: none;
      line-height: 0.7;
    }
  }

  .panel-body {
    .col {
      padding: 0;
    }
  }
}
</style>

<template>
  <GrowTransition>
    <div class="row panel panel-default">
      <div class="panel-heading">
        <button type="button" aria-label="Abort" class="close" @click="abort">
          <span aria-hidden="true">×</span>
        </button>
        <h3 class="panel-title">Please specify mailbox address</h3>
      </div>
      <div class="panel-body">
        <form accept-charset="UTF-8" role="form" class="" @submit.prevent="add">
          <fieldset>
            <div class="col col-sm-6 col-xs-12">
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" ref="emailInput" v-model="user" placeholder="E-mail" name="email" type="text">
                  <div class="input-group-addon">@</div>
                </div>
              </div>
            </div>
            <div class="col col-sm-6 col-xs-12">
              <div class="form-group">
                <select v-model="host" class="form-control">
                  <option v-for="domain in uiDomains"
                    :selected="domain == host ? 'selected' : ''"
                    :value="domain">
                   {{ domain }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col col-xs-12">
              <input class="btn btn-primary" type="submit" value="Add inbox">
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </GrowTransition>
</template>

<script>

import GrowTransition from 'components/grow_transition'
import MailboxFormatter from 'components/mailbox_formatter'
import { mapGetters } from 'vuex'

export default {
  components: {
    GrowTransition,
  },

  data () {
    return {
      user: '',
      host: this.$store.getters.uiDomains[0],
    }
  },

  computed: {
    ...mapGetters([
      'uiDomains',
    ])
  },

  mounted () {
    setTimeout(() => {
      this.$refs.emailInput.focus()
    }, 350);
  },

  methods: {
    abort () {
      this.$store.commit('uiToggleNewMailboxForm');
    },

    add () {
      const box = MailboxFormatter.prepare(this.user, this.host);
      this.$store.commit('uiToggleNewMailboxForm');
      this.$router.push({name: 'inbox', params: { mailbox: box }});
    },
  }
}

</script>
