import Vuex from 'vuex'
import Vue from 'vue'

const methods = {
  clearMailbox (mailbox) {
    return Vue.prototype.$http.delete(process.env.RAILS_URL + "/api/v1/mailboxes/" + mailbox)
  },

  loadMailbox (mailbox) {
    return Vue.prototype.$http.get(process.env.RAILS_URL + "/api/v1/mailboxes/" + mailbox)
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err)
    });
  },

  lsGet (key) {
    try {
      var item = localStorage.getItem(key);
      return JSON.parse(item);
    } catch(e) {
      return undefined;
    }
  },

  lsSet (key, obj) {
    try {
      localStorage.setItem(key, JSON.stringify(obj));
      return true
    } catch(e) {
      return false;
    }
  },
};

const store = {
  state: {
    currentMailbox: undefined,
    mailboxes: [],
  },

  getters: {
    currentMailbox (state) {
      return state.currentMailbox;
    },
    allMailboxes (state) {
      return state.mailboxes;
    },
  },

  actions: {
    load ({commit, dispatch}) {
      var mailboxes = methods.lsGet('mailboxes');
      if (mailboxes && _.isArray(mailboxes)) {
        _.each(mailboxes, (m) => {
          commit('addMailbox', {name: m});
          dispatch('loadMailbox', m);
        });
      }
    },

    logout ({commit}) {
      commit('logout');
    },

    unwatchMailbox ({state, dispatch, commit}, mailboxName) {
      commit('unwatchMailbox', mailboxName);
    },

    clearMailbox ({dispatch, state}) {
      if (!state.currentMailbox) return Promise.reject('no mailbox selected');

      methods.clearMailbox(state.currentMailbox.name)
      .then(() => {
        dispatch('loadMailbox', state.currentMailbox.name);
      })
    },

    loadMailbox ({commit, dispatch, state}, mailbox) {
      methods.loadMailbox(mailbox)
      .then((response) => {
        commit('addMailbox', {name: mailbox})
        commit('setMailData', {mails: response.data.mails, mailbox: mailbox});
      })
    },

    switchToMailbox ({commit, state}, mailbox) {
      if (mailbox) {

        return methods.loadMailbox(mailbox)
          .then((response) => {
            if (!_.find(state.mailboxes, {name: mailbox})) {
              commit('addMailbox', {name: mailbox})
            }
            if (state.currentMailbox != mailbox) {
              commit('chooseMailbox', mailbox);
            }
            commit('setMailData', {mails: response.data.mails, mailbox: mailbox});
          })
      } else {
        return Promise.reject('no name given')
      }
    },

  },

  mutations: {
    logout (state) {
      state.currentMailbox = null;
      state.mailboxes = [];
      methods.lsSet('mailboxes', []);
    },

    chooseMailbox (state, name) {
      const mbox = _.find(state.mailboxes, {name: name});

      if (mbox && mbox.name != state.currentMailbox) {
        Vue.set(state, 'currentMailbox', mbox)
      } else {
        console.warn('Could not find mbox', state.mailboxes, name)
      }
    },

    updateMailboxUnreadCounters (state, countData) {
      _.forOwn(countData, (count, box) => {
        const mailbox = _.find(state.mailboxes, {name: box});
        if (count != undefined && mailbox && mailbox.unread != count) {
          mailbox.unread = count;
        }
      });
    },

    unwatchMailbox (state, mailboxName) {
      if (!mailboxName) return;
      const newBoxes = _.filter(state.mailboxes, m => m.name != mailboxName);
      Vue.set(state, 'mailboxes', newBoxes)
      methods.lsSet('mailboxes', _.map(state.mailboxes, 'name'));
    },

    addMailbox (state, mailboxData) {
      if (_.find(state.mailboxes, {name: mailboxData.name})) {
        return;
      }

      const newBox = _.extend({
        unread: 0,
      }, mailboxData);

      const mailboxes = _(state.mailboxes).concat(newBox).sortedUniqBy(m => m.name).value()
      Vue.set(state, 'mailboxes', mailboxes)
      methods.lsSet('mailboxes', _.map(state.mailboxes, 'name'));
    },
  }
};

export default store
