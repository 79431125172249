<template>
  <div id="app">
    <LoadingState></LoadingState>
    <aside class="main-sidebar">
      <Navigation></Navigation>
    </aside>

    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content">
          <AddMailboxForm v-if="addMailFormActive"></AddMailboxForm>
          <router-view></router-view>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Navigation from 'components/navigation.vue'
import AddMailboxForm from 'components/add_mailbox_form.vue'
import LoadingState from 'components/loading_state.vue'

export default {
  components: {
    Navigation,
    AddMailboxForm,
    LoadingState,
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      allMailboxes: 'allMailboxes',
      currentMailbox: 'currentMailbox',
      addMailFormActive: 'uiShowNewMailboxForm',
    }),
  },
  methods: {
    checkRoute() {
      if (this.$route.name == 'home') {
        // redirect to first mailbox or login
        if (this.allMailboxes.length > 0) {
          const box = this.allMailboxes[0];
          return this.$router.push({name: 'inbox', params: {mailbox: box.name}})
        } else {
          return this.$router.push({name: 'login'});
        }
      }
    },
  },

  watch: {
    '$route' () {
      this.checkRoute();

    },
  },
  created () {
    this.checkRoute();
  }
}
</script>
