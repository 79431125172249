const store = {
  state: {
    showNewMailboxForm: false,
    domains: JSON.parse(document.querySelector('body').getAttribute('data-domains')),
  },

  getters: {
    'uiDomains' (state) {
      return state.domains;
    },

    'uiShowNewMailboxForm' (state) {
      return state.showNewMailboxForm;
    },
  },

  mutations: {
    uiToggleNewMailboxForm (state) {
      state.showNewMailboxForm = !state.showNewMailboxForm;
    },
  },
}

export default store
