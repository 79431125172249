<template>
  <div class="mail-view row">
    <div class="col-xs-12 gone-message" v-if="showGoneMessage">
      <div class="alert alert-danger">
        <i class="fa fa-3x fa-exclamation-triangle" style="vertical-align: middle"></i>
        <strong>Oops!</strong> This message was deleted in the meantime.
      </div>

      <router-link v-if="currentMailbox" :to="{name: 'inbox', params: { mailbox: currentMailbox.name }}" class="btn btn-primary">
        <i class="fa fa-angle-left"></i>
        Back to inbox
      </router-link>
    </div>

    <div class="col-xs-12" v-if="showViewport">
      <div class="box-header with-border">
        <div class="user-block">
          <span class="letter" v-text="mailFromLetter"></span>
          <img :src="mailGravatarUrl" alt="User Image">


          <div class="username">
            <span v-text="mailSubject"></span>
          </div>

          <div class="description">
            <div class="row">

              <div class="col-xs-12 col-sm-7 col-md-7">
                <div class="icon-row">
                  <i class="fa fa-envelope"></i>
                  <span v-text="mailFrom"></span>
                </div>

                <div class="icon-row">
                  <i class="fa fa-calendar"></i>
                  <span class="date" v-text="mailDate"></span>
                </div>

                <div class="icon-row" v-if="mail.has_attachments && mail.attachments && mail.attachments.length > 0">
                  <ul class="list-inline">
                    <li class="attachment" v-for="attachment in mail.attachments" :key="attachment.filename">
                      <i class="fa fa-paperclip"></i>
                      <a target="_blank" rel="noopener noreferrer"
                        :title="attachment.filename"
                        :href="attachment.url" >
                        <span class="name" v-text="attachment.filename"></span>
                      </a>
                      (<span class="size">{{ attachment.size | byteFormat }}</span>)
                      <a rel="noopener noreferrer"
                        :title="attachment.filename"
                        :href="directDownloadUrl(attachment)">
                        <i class="fa fa-download"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-xs-12 col-sm-5 col-md-5">
                <div class="box-tools">
                  <router-link v-if="currentMailbox" :to="{name: 'inbox', params: { mailbox: currentMailbox.name }}" class="btn btn-primary">
                    <i class="fa fa-angle-left"></i>
                    Back to inbox
                  </router-link>
                  <a class="btn btn-danger btn-md" @click.prevent="deleteMessage">
                    <i class="fa fa-trash-o"></i> Delete
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 class="h4"></h1>

      <RIframe :src="mailPreviewUrl"></RIframe>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import RIframe from 'components/resized_iframe.vue'
import MailDetails from 'components/mail_details_mixin'

export default {
  mixins: [
    MailDetails,
  ],

  components: {
    RIframe,
  },

  data () {
    return {
      wasLoaded: false,
    }
  },

  props: {
    message_id: {
      type: String,
      required: true,
    },
  },

  mounted () {
    this.$store.dispatch('fetchMessage', this.message_id)
      .then((mail) => {
        this.$nextTick(() => {
          this.wasLoaded = true;
        });
        if (!this.currentMailbox) {
          this.$store.dispatch('switchToMailbox', mail.mailbox);
        }
      })
      .catch((err) => {
        if (this.currentMailbox) {
          return this.$router.push({name: 'inbox', params: {mailbox: this.currentMailbox.name}});
        } else {
          return this.$router.push({name: 'home'});
        }
      });
  },

  methods: {
    directDownloadUrl ({url}) {
      return url + '?direct=1';
    },

    deleteMessage () {
      const mailbox = this.mail.mailbox;

      this.$store.dispatch('deleteMessage', {id: this.message_id})
      .then(() => {
        this.$router.push({name: 'inbox', params: {mailbox: mailbox}});
      })
    },
  },

  computed: {
    showViewport () {
      return this.wasLoaded && this.mail;
    },

    showGoneMessage () {
      return this.wasLoaded && !this.isLoading && !this.mail;
    },

    mail () {
      var m = _.find(this.allMails, m => m.message_id == this.message_id);
      if (!m) return null;
      return m;
    },
    ...mapGetters([
      'isLoading',
      'allMails',
      'currentMailbox',
    ]),
  },
}

</script>

<style scoped lang="scss">
@import "~assets/_my_variables.scss";

.box-header {
  padding: 10px 0;
}

.description {
  position: relative;
}

.box-tools {
  .btn {
    margin-top: 6px;
  }
  .btn-primary {
    margin-right: 16px;
  }
}

.attachment {
  a .fa {
    margin-left: 5px;
    vertical-align: middle;
  }
}

.gone-message {
  margin-top: 16px;
}

@media (max-width: $screen-md-min) {
  .box-tools {
    .btn {
      margin-bottom: 6px;
    }
  }
}

@media (max-width: $screen-sm-min) {
  .box-tools {
    .btn {
      margin-bottom: 0;
    }
  }
}
</style>
