<template>
  <div class="text-center">
    <p></p>
    <p>
      <i class="fa fa-3x fa-refresh fa-spin"></i>
    </p>
  </div>
</template>

<script>

export default {
  created () {
    this.$nextTick(() => {
      this.$store.dispatch('logout')
      .then(() => {
        document.location.href = '/';
        // this.$router.push({name: 'home'})
      })
    })
  }
}

</script>
