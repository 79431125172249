import Vuex from 'vuex'

import MailMessagesStore from 'stores/mail_messages_store'
import MailboxStore from 'stores/mailbox_store'
import LoadingStore from 'stores/loading_store'
import UiStore from 'stores/ui_store'

import {MailboxChannelPlugin} from 'stores/cable'
import MailboxCounterPlugin from 'stores/mailbox_counter_plugin'

const store = new Vuex.Store({
  modules: {
    mails: MailMessagesStore,
    mailboxes: MailboxStore,
    loading: LoadingStore,
    ui: UiStore,
  },

  plugins: [
    MailboxChannelPlugin,
    MailboxCounterPlugin,
  ],

})

store.dispatch('load');

export default store
