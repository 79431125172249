const store = {
  state: {
    loading: 0,
  },

  getters: {
    isLoading (state) {
      return state.loading > 0;
    },
  },

  mutations: {
    startedHttpRequest (state) {
      state.loading += 1;
    },

    finishedHttpRequest (state) {
      state.loading -= 1;
    },
  },
}

export default store
