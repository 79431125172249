<template>
  <span>
    <a class="" ref="copyToClipboard" :data-clipboard-text="value" title="Copy to clipboard">
      <img src="~assets/clipboard.svg" alt="Copy to clipboard">
    </a>
    <transition name="fade">
      <span class="copied" v-if="showTT">
        Copied!
      </span>
      <span class="copied error" v-if="showError">
        Error! Please copy manually.
      </span>
    </transition>
  </span>
</template>

<script>
import Clipboard from 'clipboard'

export default {
  data () {
    return {
      showTT: false,
      showError: false,
    }
  },

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  methods: {
    clipboardButton () {
      this.clipboard = new Clipboard(this.$refs.copyToClipboard);
      this.clipboard.on('success', () => {
        this.showTT = true;
        setTimeout(() => {
          this.showTT = false;
        }, 3000);
      });

      this.clipboard.on('error', () => {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 3000);
      });
    },
  },

  mounted () {
    this.clipboardButton();
  },
}
</script>

<style scoped lang="scss">
@import "~assets/_my_variables.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

img {
  width: 18px;
  height: 22px;
  vertical-align: text-top;
}

a {
  cursor: pointer;
}
.copied {
  font-size: 12px;
  color: $brand-success;
}
.error {
  color: $brand-danger;
}
</style>
