<template>
  <div class="mailbox-list row">
    <div class="col-md-12 header">
      <h1 class="h2">Hi there,
        <small class="mailbox-name-area">
          <span v-text="mailbox" class="mailbox-name"/>
          <ClipboardButton :value="mailbox"/>
        </small>
      </h1>

      <div class="action-bar text-right">
        <a class="btn btn-primary" @click.prevent="refresh">
          <i class="fa fa-refresh"></i> Refresh
        </a>

        <a class="btn btn-danger btn-md" @click.prevent="confirmClearing" v-if="!noMessages">
          <i class="fa fa-trash-o"></i> Empty mailbox
        </a>

        <a class="btn btn-primary btn-md" @click.prevent="unwatch">
          <i class="fa fa-times"></i> Unwatch
        </a>
      </div>
    </div>

    <div v-if="noMessages && !isLoading" class="col-xs-12 empty-box">
      <div class="alert alert-warning">
        No messages present. New messages will appear automatically once they arrive.
      </div>

      <div class="empty-box-bg"></div>
    </div>

    <transition-group name="slide-list" tag="div" class="col-xs-12 mail-list"
      :class="{present: allMails.length}"
      @leave="onLeaveBulkCheck"
    >
      <MailMessage :mail="mail"
        v-for="mail in allMails"
        :key="mail.message_id"
        v-on:delete="confirmDeletion"
      ></MailMessage>
    </transition-group>

    <ConfirmationDialog type="alert"
      v-if="confirmClearingDialog"
      title="Delete all messages?"
      v-on:cancel="cancelClearing()"
      v-on:confirm="clearMailbox">

      <p>Are you sure you want to delete all messages in this mailbox?</p>
    </ConfirmationDialog>

    <ConfirmationDialog type="alert"
      v-if="deleting"
      title="Delete message?"
      v-on:cancel="cancelDeletion()"
      v-on:confirm="deleteMessage(deleting)">

      <p>Are you sure you want to delete "{{deleting.subject}}" forever?</p>
    </ConfirmationDialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import MailMessage from 'components/mail_message.vue'
import ConfirmationDialog from 'components/confirmation_dialog.vue'
import ClipboardButton from 'components/clipboard_btn.vue'

export default {
  components: {
    MailMessage,
    ConfirmationDialog,
    ClipboardButton,
  },

  data () {
    return {
      deleting: false,
      confirmClearingDialog: false,
    }
  },

  props: {
    mailbox: {
      type: String,
      required: true,
    },
  },

  methods: {
    refresh () {
      this.$store.dispatch('switchToMailbox', this.mailbox);
    },

    unwatch () {
      const otherMailbox = _.find(this.allMailboxes, m => m.name != this.mailbox);

      if (otherMailbox) {
        this.$store.dispatch('unwatchMailbox', this.mailbox);
        this.$store.commit('chooseMailbox', otherMailbox.name);
        return this.$router.push({name: 'inbox', params: {mailbox: otherMailbox.name}});
      } else {
        this.$router.push({name: 'logout'})
      }
    },

    confirmClearing () {
      this.confirmClearingDialog = true;
    },
    cancelClearing () {
      this.confirmClearingDialog = false;
    },
    clearMailbox () {
      this.$store.dispatch('clearMailbox');
      this.cancelClearing();
    },

    confirmDeletion (id) {
      this.deleting = id;
    },
    cancelDeletion () {
      this.deleting = false;
    },

    deleteMessage (data) {
      const mId = data.id;
      if (mId) {
        this.$store.dispatch('deleteMessage', {id: mId});
      }
      this.deleting = false;
    },

    onLeaveBulkCheck (el) {
      if (this.allMails.length == 0) {
        if (el.nextSibling || el.previousSibling) {
          el.classList.add('hidden');
          return false
        }
      }
    },

  },

  computed: {
    noMessages () {
      return this.allMails.length == 0;
    },

    ...mapGetters([
      'allMailboxes',
      'allMails',
      'isLoading',
    ]),
  },

  mounted () {
    this.refresh();
  },

  watch: {
    'mailbox' (nv) {
      if (nv) {
        this.refresh();
      }
    },
  },
}

</script>


<style lang="scss">
h1 {
  .mailbox-name-area {
  }
}
</style>

<style scoped lang="scss">
@import "~assets/_my_variables.scss";

.header {
  line-height: 64px;
  border-bottom: 1px solid #dadada;

  h1 {
    float: left;
  }

}
.action-bar {
  line-height: 64px;
  float: right;
}

.mailbox-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: calc(100% - 25px);
  margin-right: 5px;
}

.mail-card {
  transition: all .7s;

  &.hidden {
    display: none;
  }
}
.slide-list-enter,
.slide-list-leave-to
{
  opacity: 0;
  transform: translateY(30px);
}

.slide-list-leave-active {
  position: absolute;
}

.mail-list {
  margin-top: 8px;
  padding-left: 0;
  padding-right: 0;
}

.empty-box {
  margin-top: 15px;
}
.empty-box-bg {
  min-height: 450px;
  background: url(~assets/homepage/logo.jpg) no-repeat;
  background-position: center center;
  opacity: 0.1;
}
  .alert-warning {
    background: #fbf8d8;
  }

@media (max-width: $screen-sm-max) {
  .header,
  .action-bar {
    line-height: 50px;
  }
  h1 {
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

@media (max-width: $screen-xs-max) {
  .header,
  .action-bar {
    line-height: 45px;
  }
  .header {
    h1,
    .action-bar {
      float: none;
    }

    .btn {
      i { display: none; }
    }
  }

  h1 {
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 0;
  }
}
</style>
