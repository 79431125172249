
var MailboxCounterPlugin = store => {
  var updateMailboxUnreadCounter = (state) => {
    var newCount = {};

    _.forEach(state.mailboxes.mailboxes, (mailbox) => {
      const mails = state.mails.mails[mailbox.name] || [];
      const count = _.countBy(mails, m => m.unread)[true] || 0;

      if (mailbox.unread != count) {
        newCount[mailbox.name] = count;
      }
    });

    return newCount;
  }

  store.subscribe(({type, payload}, state) => {
    if (type == 'updateMailboxUnreadCounters') return;

    const newCount = updateMailboxUnreadCounter(state);

    // check if any value changed.
    if (_.keys(newCount).length > 0) {
      store.commit('updateMailboxUnreadCounters', newCount);
    }
  });
};

export default MailboxCounterPlugin
