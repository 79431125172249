import { createConsumer} from "@rails/actioncable"

const cable = createConsumer()

var MailboxChannelPlugin = store => {
  var cableListener = {};

  const addListener = (mailbox) => {
    var sub = cable.subscriptions.create(
      {
        channel: 'MailChannel',
        inbox: mailbox,
      },
      {
        received(data) {
          const callback = `handle_${data.action}`
          return (typeof this[callback] === "function" ? this[callback](data) : undefined)
        },

        handle_destroy (data) {
          store.dispatch('processMailChannelData', data);
        },

        handle_create (data) {
          store.dispatch('processMailChannelData', data);
        },

        handle_confirmed (data) {
          var ts = data.ts;
          if (ts) {
            store.dispatch('checkLastMailDate', {
              mailbox: data.identifier,
              ts: ts,
            });
          }
        },
      }
    );

    return sub;
  }

  store.subscribe(({type, payload}, state) => {
    if (type == 'addMailbox') {
      const mbox = payload.name;
      if (!mbox) return;

      if (!cableListener[mbox]) {
        cableListener[mbox] = addListener(mbox);
      }
      return;
    }

    if (type == 'unwatchMailbox') {
      const mbox = payload;
      if (!mbox) return;

      const sub = cableListener[mbox];

      if (sub) {
        cableListener[mbox] = null;
        return sub.unsubscribe();
      }
      return;
    }
  });
}

export default cable

export { MailboxChannelPlugin as MailboxChannelPlugin }
