<style lang="scss" scoped>
@import "~assets/_my_variables.scss";
.load-wrapper {
  height: 4px;
}

.load-bar {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 4px;
  background-color: $background-color;
  &.active .bar {
    opacity: 1;
    animation-play-state: running;
  }
}

.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
  opacity: 0;
  animation-play-state: paused;
  animation-duration: 3s;
  animation-name: loading;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transition: opacity .2s cubic-bezier(0.4, 0, 1, 1);
}

.bar:nth-child(1) {
  background-color: $brand-danger;
  animation-delay: 0s;
}

.bar:nth-child(2) {
  background-color: $blue;
  animation-delay: 1s;
}

.bar:nth-child(3) {
  background-color: $background-color;
  animation-delay: 2s;
}

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
</style>

<template>
  <div class="load-wrapper">
    <div class="load-bar" :class="{active: isLoading}">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'isLoading',
    ]),
  },
  // data () {
  //   return { isLoading: true }
  // }
}
</script>
