<style scoped lang="scss">
@import "~assets/_my_variables.scss";

.mail-card {
  cursor: pointer;
  margin-bottom: 0;
}

.fa.fa-circle {
  color: $brand-danger;
}

.box-tools {
  margin-top: -8px; // align with bubble
}

.mail-date {
  margin-left: 10px;
}

@media (max-width: $screen-sm-max) {
  .mail-date {
    display: block;
    margin: 0;
  }
}
</style>
<template>
  <router-link :to="{name: 'read', params:{ message_id: mail.message_id}}" tag="div" class="box box-widget mail-card">
    <div class="box-header with-border">
      <div class="user-block">
        <span class="letter" v-text="mailFromLetter"></span>
        <img class="" :src="mailGravatarUrl" alt="User Image">
        <span class="username">

          <div class="box-tools">
            <span class="btn btn-box-tool" v-if="mail.has_attachments">
              <i class="fa fa-paperclip"></i>
            </span>

            <button type="button" class="btn btn-box-tool"
              title="Mark as read"
              @click.prevent="markAsRead"
            >
              <i class="fa"
                :class="{'fa-circle': mail.unread, 'fa-circle-o': !mail.unread}"
              ></i>
            </button>

            <button type="button" @click.prevent="remove" class="btn btn-box-tool">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <router-link :to="{name: 'read', params:{ message_id: mail.message_id}}" v-text="mailSubject"></router-link>
        </span>
        <span class="description">
          <span v-text="mailFrom"></span>

          <span class="mail-date">
            <i class="fa fa-calendar"></i>
            <span v-text="mailDate"></span>
          </span>
        </span>
      </div>

    </div>
  </router-link>
</template>

<script>
import MailDetails from 'components/mail_details_mixin'

export default {
  mixins: [
    MailDetails,
  ],

  props: {
    mail: {
      type: Object,
      required: true,
    },
  },

  methods: {
    markAsRead () {
      this.$store.dispatch('fetchMessage', this.mail.message_id);
    },

    remove () {
      this.$emit('delete', {
        id: this.mail.message_id,
        subject: this.mailSubject,
      });
    },

  },
}
</script>
