<template>
  <div>
    <div class="modal-backdrop fade" v-bind:class="{in: visible}">
    </div>
    <div class="modal fade" v-bind:class="{in: visible}" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" v-bind:class="headerClass">
            <button type="button" class="close" v-on:click="cancel" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">{{ title }}</h4>
          </div>
          <div class="modal-body">
            <slot>Please confirm this action.</slot>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" v-on:click="cancel">Cancel</button>
            <button type="button" class="btn" v-bind:class="buttonClass" v-on:click="confirmIt">Confirm</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>

<script>
  export default {
    name: 'confirmation-dialog',
    data: () => {
      return {
        visible: false,
      }
    },
    props: {
      title: String,
      type: String,
    },
    computed: {
      buttonClass() {
        return {
          'btn-primary': this.type !== 'alert',
          'btn-danger': this.type === 'alert',
        }
      },
      headerClass() {
        return {
          'alert-danger': this.type === 'alert',
        }
      },
    },
    mounted() {
      setTimeout(() => {
        this.visible = true;
      });
    },
    methods: {
      cancel() {
        this.$emit('cancel');
      },
      confirmIt() {
        this.$emit('confirm');
      },
    },
  }
</script>

<style lang="scss" scoped>
.modal {
  display: block;

  .modal-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
</style>
