<style lang="scss" scoped>
@import "~assets/_my_variables.scss";
.counter-wrapper {
  position: absolute;
  display: inline;
  color: #fff;
  font-size: 10px;
  margin-top: -8px;
  margin-left: 7px;

  .counter {
    background: $brand-danger;
    line-height: initial;
    padding: 2px 5px;
    border-radius: 10px;
  }
}

.fa {
  color: #d9d9d9;
  padding-right: 5px;
  &.gt9 {
    padding-right: 10px;
  }
}
</style>

<template>
  <span>
    <span class="counter-wrapper" v-if="mailbox.unread">
      <span class="counter" v-text="mailbox.unread"></span>
    </span>
    <i class="fa fa-envelope" :class="{gt9: mailbox.unread > 9}"></i>
  </span>
</template>

<script>

export default {
  name: 'confirmation-dialog',
  data: () => {
    return {}
  },
  props: {
    mailbox: {
      type: Object,
      required: true,
    },
  },
}

</script>
