<style lang="scss">
.grow-enter-active,
.grow-leave-active {
  transition: max-height .3s ease-in-out;
  overflow: hidden;
}
</style>

<template>
  <transition name="grow"
    @before-enter="beforeEnter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
  >
    <slot></slot>
  </transition>
</template>

<script>

const offset = 10;

export default {
  methods: {
    beforeEnter (el) {
      // Setup clone
      var clone = el.cloneNode(true), h
      clone.style.width = el.style.width
      clone.style.visibility = 'hidden'
      clone.style.removeProperty('display')

      // get clone height

      document.body.appendChild(clone)
      h = clone.clientHeight
      clone.remove()

      // Force animation instead of simple "setting a height"
      el.style.maxHeight = "0px"
      setTimeout(() => el.style.maxHeight = (h + offset) + "px", 1)
    },

    afterEnter (el) {
      el.style.maxHeight = 'initial';
    },

    beforeLeave (el) {
      el.style.maxHeight = (el.clientHeight + offset) + "px"
      setTimeout(() => el.style.maxHeight = "0px", 1)
    },
  },
}
</script>
