import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

Vue.use(Vuex)
Vue.use(VueRouter)

import Cable from 'stores/cable'
import axios from 'axios'

Vue.prototype.$cable = Cable
Vue.prototype.$http = axios

const RootStore = require('stores/root').default

axios.interceptors.request.use((config) => {
  RootStore.commit('startedHttpRequest');
  return config;
});

axios.interceptors.response.use((response) => {
  RootStore.commit('finishedHttpRequest');
  return response;
}, (error) => {
  RootStore.commit('finishedHttpRequest');
  return Promise.reject(error);
});

const routes = [
  {
    path: '/',
    name: 'home',
    component: App,
    children: [
      {
        path: 'inbox/:mailbox',
        props: true,
        component: MailboxList,
        name: 'inbox',
      },
      {
        path: 'message/:message_id',
        props: true,
        component: MailView,
        name: 'read',
      },
      {
        path: 'logout',
        name: 'logout',
        component: Logout,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/app',
})

import Login from 'components/login.vue'
import Logout from 'components/logout.vue'
import App from 'components/app.vue'
import MailboxList from 'components/mailbox_list.vue'
import MailView from 'components/mail_view.vue'
import ByteFilter from 'components/byte_filter'

Vue.filter('byteFormat', ByteFilter);

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    router,
    store: RootStore,
    components: {
    }
  }).$mount('#vuejs')
})
