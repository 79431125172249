import Vuex from 'vuex'
import Vue from 'vue'

const methods = {
  fetchMailById (id) {
    return Vue.prototype.$http.get(process.env.RAILS_URL + "/api/v1/mails/" + id)
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err)
    });
  },

  deleteMailById (id) {
    return Vue.prototype.$http.delete(process.env.RAILS_URL + "/api/v1/mails/" + id)
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err);
      return Promise.resolve();
    });
  },

  addSingleMailData (mails, mail) {
    const existingMail = _.find(mails, { message_id: mail.message_id });
    var newMails = _.concat([], mails);

    if (existingMail) {
      newMails = _.filter(newMails, m => m.message_id != mail.message_id);
      newMails.push(_.extend({}, existingMail, mail));
    } else {
      newMails.push(mail);
    }

    return newMails;
  },

  sortMails (mails) {
    return _.orderBy(mails, ['created_at'], ['desc']);
  },
};

const store = {
  state: {
    mails: {
      // mailbox : mails,
    },
  },

  getters: {
    allMails (state, getters) {
      const box = getters.currentMailbox;
      if (!box) return [];
      return state.mails[box.name] || [];
    },
  },

  actions: {
    checkLastMailDate ({state, dispatch}, {mailbox, ts}) {
      const mails = state.mails[mailbox] || [];
      if (!ts) return;

      const dates = _.map(mails, 'updated_at');
      const lastDate = _.last(_.sortBy(dates));

      if (lastDate == ts) {
        // do nothing
      } else {
        dispatch('loadMailbox', mailbox);
      }
    },

    processMailChannelData ({commit}, {action, mail}) {
      if (action == 'create') {
        commit('addSingleMailData', mail);
      } else if (action == 'destroy') {
        commit('removeMail', mail.message_id);
      }
    },

    fetchMessage ({commit}, id) {
      return methods.fetchMailById(id).then((response) => {
        commit('addSingleMailData', response.data);
        return response.data;
      });
    },

    deleteMessage ({commit}, {id}) {
      return methods.deleteMailById(id).then(() => {
        commit('removeMail', id);
      });
    },
  },

  mutations: {
    removeMail (state, id) {
      _.forOwn(state.mails, (value, mailbox) => {
        var mailFound = false;
        const mailboxMails = value || [];
        const newMails = _.filter(mailboxMails, (m) => {
          mailFound = mailFound || (m.message_id == id);
          return m.message_id != id
        });

        if (mailFound) {
          Vue.set(state.mails, mailbox, newMails);
        }
      });
    },

    setMailData (state, mailData) {
      const mailbox = mailData.mailbox;
      const mails = mailData.mails;

      var newMails = state.mails[mailbox] || [];
      _.forEach(mails, (m) => {
         newMails = methods.addSingleMailData(newMails, m);
      })

      var validMailIds = _.map(mails, 'message_id');
      newMails = _.filter(newMails, m => validMailIds.indexOf(m.message_id) >= 0);
      newMails = methods.sortMails(newMails);

      Vue.set(state.mails, mailbox, newMails)
    },

    addSingleMailData (state, mail) {
      const mailboxMails = state.mails[mail.mailbox] || [];
      var newMails = methods.addSingleMailData(mailboxMails, mail);
      newMails = methods.sortMails(newMails);

      Vue.set(state.mails, mail.mailbox, newMails)
    },
  }
};

export default store
