<template>
  <div>
    <iframe ref="iframe" v-if="src && src.length" :src="src" width="100%" marginheight="0" frameborder="0" scrolling="no"></iframe>
  </div>
</template>

<script>
import { iframeResizer } from 'iframe-resizer'

export default {
  props: {
    src: {
      default: '',
      type: String,
    },
  },

  mounted () {
    this.$nextTick(() => {
      var iframe = this.$refs.iframe
      this.iframe = iframe;

      iframeResizer({
        log: false,
        interval: 100,
      }, iframe);
    });
  },

  beforeDestroy () {
    this.iframe.iFrameResizer.close();
  },
}

</script>
