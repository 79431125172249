<style lang="scss" scoped>
@import "~assets/_my_variables.scss";

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  $border-width: 5px;

  display: block;
  border-left: $border-width solid transparent;
  position: relative;
  height: $navbar-height;
  cursor: pointer;

  &.link:not(.active):hover {
    background: darken($grey-dark, 10%);
    border-left: $border-width solid $yellow;
  }

  &.active {
    background: #fff;
    margin-right: calc($navbar-height / 2);
    border-left: $border-width solid $blue;

    a {
      color: $blue;
    }

    &:after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #fff;
      border-width: calc($navbar-height / 2);
      margin-top: calc(-1 * $navbar-height / 2);
    }
  }

  &.header {
    margin: 15px 0 0px 0;
    background: transparent;
    color: $yellow;
    font-weight: bold;
    line-height: $navbar-height;
    margin-left: 10px;
    cursor: initial;
  }

  a {
    line-height: $navbar-height;
    display: block;
    color: #fff;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.top-nav {
  text-align: right;
  padding-right: 10px;
}

.logo {
  max-width: $navbar-height;
  margin-left: 12px;
  margin-top: 6px;
}
.brand {
  float: left;
  font-family: 'Droid Serif', serif;
  margin-top: 7px;
    img {
      vertical-align: baseline;
    }
  h2 {
    display: inline;
    position: relative;
    padding-left: 25px;
    top: -10px;
    color: #fff;
  }
}
.block-intro {
  text-align: center;
  color: $yellow;
  line-height: $navbar-height;
  clear: left;

  p {
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.rl {
  line-height: $navbar-height;
  display: inline-block;
  padding: 0 10px;
  font-size: 30px;
}

.navbar-toggle {
  margin-right: 0;
  margin-top: 15px;
  background: transparent;
  border: none;
}

.icon-bar {
  background: #fff;
  height: 3px;
  width: 25px;
  display: block;
  margin: 5px 0;
  border-bottom: 1px solid lighten($navbar-default-bg, 30%);
}
.strong { font-weight: bold; }

@media (min-width: $screen-xs-max) {
}

@media (max-width: $screen-xs-max) {
  .logo {
    margin-top: 0;
  }
}

</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 top-nav">
        <a href="/" class="brand">
          <img class="logo" src="~assets/logowhite.png">
          <h2>
            Duckspam
          </h2>
        </a>

        <div class="pull-right">
          <button type="button" class="navbar-toggle" @click.prevent="toggleNav">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>

        <div class="block-intro hidden-sm hidden-md hidden-lg" v-if="currentMailbox">
          <p class="mobile-box-info strong">
            <MailCounter :mailbox="currentMailbox"></MailCounter>
            <span v-text="currentMailbox.name"></span>
          </p>
        </div>
      </div>
    </div>

    <ul class="nav-list" :class="{open: forceOpen}">
      <li class="header">Inboxes</li>
      <li v-for="mailbox in allMailboxes" :key="mailbox.name" class="mailbox link" :class="{unread: mailbox.unread > 0, active: mailbox.name == currentMailboxName}">
        <router-link :to="{name: 'inbox', params: { mailbox: mailbox.name }}">
          <MailCounter :mailbox="mailbox"></MailCounter>
          <span v-text="mailbox.name"></span>
        </router-link>
      </li>

      <li class="header">More</li>
      <li class="link">
        <a @click.prevent="toggleNewMailbox">Watch more</a>
      </li>
      <li class="link">
        <router-link :to="{name: 'logout'}">Logout</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MailCounter from 'components/mail_counter'

export default {
  components: {
    MailCounter,
  },

  data () {
    return {
      forceOpen: false,
    }
  },

  computed: {
    currentMailboxName () {
      return this.currentMailbox ? this.currentMailbox.name : "";
    },

    ...mapGetters([
      'allMailboxes',
      'currentMailbox',
      'unreadMailboxes',
    ]),
  },

  methods: {
    toggleNewMailbox () {
      this.$store.commit('uiToggleNewMailboxForm');
      this.forceOpen = false;
    },

    toggleNav () {
      this.forceOpen = !this.forceOpen;
    }
  },

  watch: {
    '$route' () {
      this.forceOpen = false;
    },
  }
}

</script>
