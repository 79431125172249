import moment from 'moment'

export default {
  computed: {
    mailPreviewUrl () {
      if (!this.mail) return undefined;
      return "/app/message/" + this.mail.message_id + "/html";
    },
    mailSubject () {
      return this.mail && this.mail.subject || '(No subject)';
    },
    mailDate () {
      const date = this.mail && this.mail.created_at;
      return  date && moment(date).format('LLL') || '(Error)';
    },
    mailFrom () {
      return this.mail && this.mail.from || 'X';
    },
    mailFromLetter () {
      return _.replace(this.mailFrom, /["\']/, '')[0];
    },
    mailGravatarUrl () {
      return this.mail && this.mail.gravatar_url;
    },
  },
}
